/**
 * Original file from app_deborla cartridge
 * Modifications:
 * - changed .no-menu-header paddint-top to 67px (line 47)
 */

// -----------------------------------------------------------------------------
//  Content Wrapper:
// -----------------------------------------------------------------------------

.content {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    opacity: 1;
    transition: opacity 0.4s ease;
}

.overflowhidden {
    overflow: hidden;
}

.page {
    position: relative;
    width: 100%;
    padding-top: ($header-height-no-banner - 3.6rem);

    .message-banner-hidden {
        display: none;
    }

    .message-banner-hidden & {
        padding-top: ($header-height-no-banner - 3.6rem);
    }

    .footer-fixer {
        min-height: calc(100vh - 71rem);
    }

    .footer-fixer-no-footer {
        min-height: calc(100vh - 24rem);
    }

    &.no-menu-header {
        padding-top: 67px;

        .footer-container-copyright {
            position: static;
            bottom: 0;
            width: 100%;
            margin-top: $base-margin * 10;
        }
    }
}
// -----------------------------------------------------------------------------
//  Wrapper:
// -----------------------------------------------------------------------------

.wrapper {
    position: relative;
    padding: 0 $base-padding * 2;
    margin: 0 auto;
}

// -----------------------------------------------------------------------------
//  Screen Size Indicator:
// -----------------------------------------------------------------------------

.screen-size-indicator {
    position: absolute;
    top: -999em;
    left: -999em;

    &::before {
        content: 'small';
    }
}

@media screen and (min-width: $tablet-width) {
    .screen-size-indicator::before {
        content: 'medium';
    }
}

@media screen and (min-width: $desktop-width) {
    .screen-size-indicator::before {
        content: 'large';
    }
}

// -----------------------------------------------------------------------------
//  Smooth Overflow Scrolling:
// -----------------------------------------------------------------------------

.scrollable,
%scrollable {
    .no-touchevents & {
        overflow: auto;
    }

    .touchevents & {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; // has to be scroll, not auto
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

// -----------------------------------------------------------------------------
//  Image Wrapper:
// -----------------------------------------------------------------------------

.image {
    display: block;
    max-width: 100%;
    height: 0;
    opacity: 0;
    transition: opacity 0.4s ease;
}

// -----------------------------------------------------------------------------
//  Image
// -----------------------------------------------------------------------------

img {
    width: 100%;
    height: 100%;
}

*:focus {
    outline: none;
}

// -----------------------------------------------------------------------------
//  Lazyload
// -----------------------------------------------------------------------------

.lazyload,
.lazyloading,
.lazyloaded {
    transition: 0.3s opacity ease-out;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
}

// -----------------------------------------------------------------------------
//  Prices
// -----------------------------------------------------------------------------

.price-adjusted {
    color: $red;
    font-family: 'Montserrat', sans-serif;
}

.price-non-adjusted {
    color: $gray-dark;
    font-family: 'Montserrat', sans-serif;
    text-decoration: line-through;
}

.price-sales {
    color: $gray-dark;
    font-family: 'Montserrat', sans-serif;
}

.sale-price {
    color: $gray-dark;
    font-family: 'Montserrat', sans-serif;
}

.strike-through {
    color: $red;
    font-family: 'Montserrat', sans-serif;
    text-decoration: line-through;

    .value {
        color: $gray-dark;
    }
}

// -----------------------------------------------------------------------------
//  hidden
// -----------------------------------------------------------------------------

.d-none {
    display: none;
}

// -----------------------------------------------------------------------------
//  Search
// -----------------------------------------------------------------------------

.search-open {
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

fieldset {
    padding: 0;
    border: 0;
    margin: 0;
}

.page-designer-reference {
    #home {
        margin-bottom: ($base-margin * 2.5);
    }
}

@import 'screens/large/general';
