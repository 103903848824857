.ldBar {
  position: relative;
}
.ldBar.label-center > .ldBar-label {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #fff;
  font-size: 14px;
  font-weight: 900;
}
.ldBar-label:after {
  content: "";
  display: inline;
}
.ldBar.no-percent .ldBar-label:after {
  content: "";
}
